var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseInput", {
    attrs: {
      label: _vm.attrs.label,
      disabled: _vm.attrs.disabled,
      hideLabel: _vm.hideLabel
    },
    scopedSlots: _vm._u([
      {
        key: "input",
        fn: function() {
          return [
            _vm.hasError
              ? _c("v-alert", { attrs: { type: "error" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("components.inputs.TypeInput.error")) +
                      " "
                  )
                ])
              : _c(
                  "v-select",
                  _vm._g(
                    _vm._b(
                      {
                        ref: "input",
                        attrs: {
                          solo: "",
                          flat: "",
                          value: _vm.value,
                          items: _vm.availableItems,
                          "item-text": "select_text",
                          "return-object": _vm.returnObject,
                          loading: _vm.computedLoading
                        },
                        on: { input: _vm.input }
                      },
                      "v-select",
                      _vm.attrs,
                      false
                    ),
                    _vm.listeners
                  )
                )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }