var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "mt-0 mt-md-6 mt-md-0",
    attrs: {
      items: _vm.items,
      headers: _vm.$vuetify.breakpoint.mdAndUp ? _vm.computedHeaders : [],
      "disable-pagination": "",
      "disable-filtering": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([
      {
        key: "footer",
        fn: function() {
          return [
            _c("v-text-field", {
              staticClass: "d-none",
              attrs: { rules: _vm.gtz, type: "number" },
              model: {
                value: _vm.validItemsCounter,
                callback: function($$v) {
                  _vm.validItemsCounter = $$v
                },
                expression: "validItemsCounter"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "item",
        fn: function(ref) {
          var item = ref.item
          var index = ref.index
          return [
            _vm.hasError[index]
              ? [
                  _c(
                    "tr",
                    { staticClass: "d-flex flex-column d-md-table-row" },
                    [
                      _c("v-divider", { staticClass: "d-md-none" }),
                      _c(
                        "td",
                        {
                          staticClass:
                            "d-flex justify-space-around d-md-table-cell table-td",
                          attrs: { colspan: _vm.computedHeaders.length - 1 }
                        },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "my-5 ma-md-0",
                              attrs: { type: "error", dense: "", block: "" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("forms.GC_CommonForm.line_error")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "td",
                        {
                          staticClass:
                            "d-flex justify-space-around d-md-table-cell"
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "error",
                                icon: "",
                                disabled: _vm.items.length == 1
                              },
                              on: {
                                click: function($event) {
                                  return _vm.removeProduct(item, index)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("fas fa-trash")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              : _vm.notFound[index]
              ? [
                  _c(
                    "tr",
                    { staticClass: "d-flex flex-column d-md-table-row" },
                    [
                      _c("v-divider", { staticClass: "d-md-none" }),
                      _c(
                        "td",
                        {
                          staticClass:
                            "d-flex justify-space-around d-md-table-cell table-td",
                          attrs: { colspan: _vm.computedHeaders.length - 1 }
                        },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "my-5 ma-md-0",
                              attrs: { type: "warning", dense: "", block: "" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "forms.GC_CommonForm.line_not_found",
                                      { type_id: item.type_id }
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "td",
                        {
                          staticClass:
                            "d-flex justify-space-around d-md-table-cell"
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "error",
                                icon: "",
                                disabled: _vm.items.length == 1
                              },
                              on: {
                                click: function($event) {
                                  return _vm.removeProduct(item, index)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("fas fa-trash")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              : _c(
                  "tr",
                  { staticClass: "d-flex flex-column d-md-table-row" },
                  [
                    _c("v-divider", { staticClass: "d-md-none" }),
                    _c(
                      "td",
                      {
                        staticClass:
                          "d-flex align-center py-md-2 d-md-table-cell table-td"
                      },
                      [
                        _c("TypeInput", {
                          staticClass: "table-input",
                          attrs: {
                            label: _vm.computedHeaders[0].text,
                            hideLabel: _vm.$vuetify.breakpoint.mdAndUp,
                            placeholder: _vm.$t(
                              "forms.GC_CommonForm.type.placeholder"
                            ),
                            disabled: _vm.loading,
                            "item-value": "id",
                            returnObject: false,
                            "hide-details": "",
                            solo: "",
                            dense: ""
                          },
                          on: {
                            blur: function($event) {
                              return _vm.onTypeBlur(item, index)
                            },
                            typeLoading: function(value) {
                              return _vm.$emit("typeLoading", value)
                            }
                          },
                          model: {
                            value: item.type_id,
                            callback: function($$v) {
                              _vm.$set(item, "type_id", $$v)
                            },
                            expression: "item.type_id"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "td",
                      {
                        staticClass:
                          "d-flex align-center py-md-2 d-md-table-cell table-td"
                      },
                      [
                        _c("CurrencyInput", {
                          staticClass: "table-input",
                          attrs: {
                            label: _vm.computedHeaders[1].text,
                            hideLabel: _vm.$vuetify.breakpoint.mdAndUp,
                            placeholder: _vm.$t(
                              "forms.GC_CommonForm.value.placeholder"
                            ),
                            disabled: _vm.loading,
                            prefixed: "",
                            "hide-details": "",
                            solo: "",
                            dense: "",
                            loading: _vm.isLoading[index]
                          },
                          on: {
                            blur: function($event) {
                              return _vm.onValueBlur(item, index)
                            }
                          },
                          model: {
                            value: item.value,
                            callback: function($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.shouldShowAmount
                      ? _c(
                          "td",
                          {
                            staticClass:
                              "d-flex align-center py-md-2 d-md-table-cell table-td"
                          },
                          [
                            _c("CurrencyInput", {
                              staticClass: "table-input",
                              attrs: {
                                label: _vm.computedHeaders[2].text,
                                hideLabel: _vm.$vuetify.breakpoint.mdAndUp,
                                placeholder: _vm.$t(
                                  "forms.GC_CommonForm.amount.placeholder"
                                ),
                                suffixed: true,
                                disabled: _vm.loading,
                                "hide-details": "",
                                solo: "",
                                dense: "",
                                loading: _vm.isLoading[index]
                              },
                              on: {
                                blur: function($event) {
                                  return _vm.onAmountBlur(item, index)
                                }
                              },
                              model: {
                                value: item.amount,
                                callback: function($$v) {
                                  _vm.$set(item, "amount", $$v)
                                },
                                expression: "item.amount"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$vuetify.breakpoint.mdAndUp ||
                    (item.calculation_rule && !item.calculation_rule.in_ratio)
                      ? _c(
                          "td",
                          {
                            staticClass:
                              "d-flex justify-space-around d-md-table-cell"
                          },
                          [
                            item.calculation_rule &&
                            !item.calculation_rule.in_ratio
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "warning"
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "fas fa-exclamation-triangle"
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "forms.GC_CommonForm.zero_value_warning"
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "td",
                      {
                        staticClass:
                          "d-flex justify-space-around d-md-table-cell",
                        attrs: {
                          colspan:
                            _vm.$vuetify.breakpoint.smAndDown &&
                            item.calculation_rule &&
                            item.calculation_rule.in_ratio
                              ? 2
                              : 1
                        }
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "error",
                              icon: "",
                              disabled: _vm.items.length == 1
                            },
                            on: {
                              click: function($event) {
                                return _vm.removeProduct(item, index)
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("fas fa-trash")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
            index == _vm.items.length - 1
              ? [
                  _c("v-divider", { staticClass: "d-md-none" }),
                  _c(
                    "tr",
                    {
                      staticClass:
                        "d-flex justify-space-between d-md-table-cell mt-2 m-md-0 add-btn"
                    },
                    [
                      _c(
                        "td",
                        { attrs: { colspan: _vm.computedHeaders.length } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-0 primary--text",
                              attrs: { text: "" },
                              on: { click: _vm.addProduct }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("forms.GC_CommonForm.add_product")
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }