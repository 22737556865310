<template>
    <BaseInput :label="attrs.label" :disabled="attrs.disabled" :hideLabel="hideLabel">
        <template #input>
            <v-alert type="error" v-if="hasError">
                {{ $t('components.inputs.TypeInput.error') }}
            </v-alert>
            <v-select
                ref="input"
                solo flat
                :value="value"
                @input="input"
                v-else
                v-bind="attrs"
                v-on="listeners"
                :items="availableItems"
                item-text="select_text"
                :return-object="returnObject"
                :loading="computedLoading"
            >
            </v-select>
        </template>
    </BaseInput>
</template>

<script>
import BaseInput from '@/shared/components/inputs/BaseInput'
import { mapGetters } from 'vuex'
import Type from '@/models/Type'

export default {
    name: 'TypeInput',
    props: {
        value: {
            type: [Type, String],
            required: false,
        },
        loading: Boolean,
        returnObject: {
            default: () => true,
            type: Boolean,
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
    },
    components: { BaseInput },
    data: vm => ({
        isItemLoading: false,
        hasError: false,
        createLoading: false,
    }),
    async mounted() {
        if (!this.availableItems.length)
            await this.loadAvailableItems()
    },
    methods: {
        async loadAvailableItems() {
            this.hasError = false

            this.isItemLoading = true
            await this.$store.dispatch('type/fetch')
                .catch(this.errorHandler)
            this.isItemLoading = false
        },

        input(v) {
            // if (typeof v === 'string') {
            //     this.createNewType(v)
            //     return
            // } else if (Array.isArray(v)) {
            //     // [TODO]
            // }

            this.$emit('input', v)
        },

        // async createNewType(v) {
        //     // Procura dentre os itens carregados para ver se já existe
        //     for (let item of this.availableItems) {
        //         if (v.toLowerCase() == item.slug.toLowerCase()) {
        //             this.input(item)
        //             return
        //         }
        //     }

        //     let unit = new Type({ name: v, slug: v })
        //     this.createLoading = true
        //     let res = await unit.create()
        //         .catch(this.errorHandler)
        //     this.createLoading = false

        //     if (!res)
        //         return

        //     unit = new Type(res.data.data)

        //     this.input(unit)
        //     this.loadAvailableItems()
        // },

        errorHandler(e) {
            this.hasError = true
            this.$console.error(e)
        },

        focus() {
            this.$refs.input.focus()
        },
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { loading, ...attrs } = this.$attrs
            return { ...attrs }
        },

        computedLoading() {
            let loading = this.loading || this.isItemLoading || this.createLoading
            this.$emit('typeLoading', loading)
            return loading
        },

        ...mapGetters({
            availableItems: 'type/data',
        }),
    },
}
</script>
