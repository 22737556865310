<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" sm="12" md="12" class="pt-0">
                <v-card class="my-4 mx-2 mx-md-10 px-3 pt-1 pb-0 elevation-0">
                    <v-card-title>
                        <div class="primary--text search-title">{{ computedSearchTitle }}</div>
                    </v-card-title>

                    <v-card-text>
                        <div v-if="localLoading" class="d-flex mb-4 justify-center">
                            <v-progress-circular indeterminate/>
                        </div>
                        <SearchCustomerInput
                            v-else
                            ref="document_field"
                            fieldName="customer_document"
                            :document="localDocumentValue"
                            @changed-valididy="value => isCustomerValid = value"
                            @selected-customer="handleSelectedCustomer"
                            :value.sync="value"
                        />
                    </v-card-text>                    
                </v-card>
            </v-col>
            <template v-if="isCustomerValid && selectedCustomer">
                <v-col cols="12" sm="12" md="4">
                    <CustomerCard :customer="selectedCustomer" class="my-0 mx-0 ml-md-8 py-0 px-8  limit-user-card"/>
                </v-col>

                <v-col cols="12" sm="12" md="8" :align-self="selectedCustomer ? null : 'center'">
                    <v-card class="my-4 mx-2 mx-md-10 px-3 pt-1 pb-0 elevation-0">
                        <v-card-text>
                            <h2 class="mt-1 primary--text table-title">{{ computedTableTitle }}</h2>

                            <GCTypesTable
                                ref="table"
                                :items="value.products"
                                :loading="loading"
                                :formType="formType"
                                @typeLoading="value => $emit('typeLoading', value)"
                            />
                        </v-card-text>

                        <slot name="table-actions"></slot>
                    </v-card>
                </v-col>
            </template>
        </v-row>
    </v-form>
</template>

<script>
import SearchCustomerInput  from '@/components/inputs/SearchCustomerInput'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import CustomerCard         from '@/shared/components/cards/CustomerCard.vue'
import GCTypesTable         from './commons/GC_TypesTable'
import { roundNumber }      from '@/utils/mathUtils'
import BaseModel            from '@/models/BaseModel'
import FormMixin            from '@/mixins/FormMixin'
import Customer             from '@/models/Customer'
import { mapGetters }       from 'vuex'

export default {
    name: 'GC_CommonForm',
    mixins: [ FormMixin(BaseModel, 'document_field'), HasErrorHandlerMixin ],
    components: {
        GCTypesTable,
        SearchCustomerInput,
        CustomerCard,
    },
    props: {
        formType: String,
        id      : String,
        document: String,
    },
    data: vm => ({
        amount            : null,
        selectedCustomer  : null,
        localDocumentValue: vm.document,
        localLoading      : false,
        isCustomerValid   : false,
    }),
    async created() {
        if (this.id && !this.document)
            await this.fetchData()
    },
    mounted() {
        this.value.touch()
        this.initProducts()
    },
    methods: {
        async fetchData() {
            this.localLoading = true

            let errType = ''
            let res = await (new Customer({ id: this.id })).get()
                .catch(err => {
                    this.loading = false

                    if (err.message == 'Network Error') {
                        errType = 'network_error'
                        return 
                    }

                    if (err.response && err.response.data.type) {
                        errType = err.response.data.type
                        return
                    }

                    errType = 'error'
                })

            if (!errType && (!res || !res.data || !res.status == 200))
                errType = 'error'

            if (errType) {
                this.localLoading = false

                const definedErrors = [
                    'network_error'
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`forms.GC_CommonForm.alerts.${errType}`), 'error', resolve)
                })
                return
            }
                
            this.localDocumentValue = (new Customer(res.data)).document
            this.localLoading       = false
        }, 

        handleSelectedCustomer(value) {
            this.selectedCustomer = value
            this.$emit('selected-customer', value)

            if (value && value.id != this.id)
                this.$router.push({ name: this.formType == 'generate' ? 'generate' : 'consumption', params: { id: value.id, document: value.document } })
        },

        initProducts() {
            this.localValue.products = [
                { value: 0, amount: 0, type_id: 'default' }
            ]
        },

        async presubmit() {
            if (!this.selectedCustomer)
                return

            this.localValue.customer_id = this.selectedCustomer.id

            // Trata os itens e verifica se pelo menos um existe
            if (!this.$refs.table || !this.$refs.table.presubmit()) {
                this.$bus.$emit('message', this.$t('forms.GC_CommonForm.no_items'), 'error')
                return false
            }

            // Exibe a modal de confirmação
            let confirmationHtml = this.formType == 'generate' ? this.generateConfirmationHtml : this.consumptionConfirmationHtml
            let confirmed = await new Promise((resolve) => {
                this.$bus.$emit('confirm', confirmationHtml, resolve, true)
            })
            if (!confirmed)
                return false

            // Trata o que será enviado para a API
            this.localValue.value = this.localValue.products
                .map(({ type_id, value }) => ({ type_id, value }))
                .reduce((carry, p) => Number(roundNumber(carry + p.value)), 0)

            return true
        },
    },
    computed: {
        ...mapGetters({
            disableCashback: 'company/disable_cashback',
        }),
        computedTableTitle() {
            return this.formType == 'generate' ? this.$t('forms.GC_CommonForm.generate_title') : this.$t('forms.GC_CommonForm.consumption_title')
        },
        computedSearchTitle() {
            return this.formType == 'generate' ? this.$t('forms.GC_CommonForm.generate_search_title') : this.$t('forms.GC_CommonForm.consumption_search_title')
        },
        simulatedPointsTotalCashback() {
            return this.value.products.reduce((carry, p) => Number(roundNumber(carry + p.amount * p.calculation_rule.out_ratio)), 0)
        },
        simulatedPointsTotalPoints() {
            return this.value.products.reduce((carry, p) => Number(roundNumber(carry + p.amount)), 0)
        },
        productsTotalValue() {
            return this.value.products.reduce((carry, p) => Number(roundNumber(carry + p.value)), 0)
        },
        generateConfirmationHtml() {
            if (this.disableCashback) {
                return `<div class="text-center">${this.$t('forms.GC_CommonForm.confirmations.generate_disable_cashback', {
                    pointText: `<b class="success--text">${this.$options.filters.dynamicPoint(this.simulatedPointsTotalPoints)}</b>`,
                })}<div>`    
            }

            return `<div class="text-center">${this.$t('forms.GC_CommonForm.confirmations.generate', {
                valueText: `<b class="success--text">${this.$options.filters.currency(this.simulatedPointsTotalCashback)}</b>`,
                pointText: `<b class="success--text">${this.$options.filters.dynamicPoint(this.simulatedPointsTotalPoints)}</b>`,
            })}<div>`
        },
        consumptionConfirmationHtml() {
            return `<div class="text-center">${this.$t('forms.GC_CommonForm.confirmations.consumption', { valueText: `<b class="error--text">${this.$options.filters.currency(this.productsTotalValue)}</b>` })}<div>`
        },
    },
}
</script>

<style lang="scss" scoped>
.search-title {
    font-size: 16px;
    font-weight: bold;
}

.table-title {
    font-size: 16px !important;
    font-weight: bold !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px !important;
}
</style>