<template>
    <v-data-table
        class="mt-0 mt-md-6 mt-md-0"
        :items="items"
        :headers="$vuetify.breakpoint.mdAndUp ? computedHeaders : []"
        disable-pagination
        disable-filtering
        disable-sort
        hide-default-footer
    >
        <template #footer>
            <!-- Input hidden apenas para validar a form pai -->
            <v-text-field
                v-model="validItemsCounter"
                :rules="gtz"
                type="number"
                class="d-none"
            ></v-text-field>
        </template>

        <template #item="{ item, index }">
            <template v-if="hasError[index]">
                <tr class="d-flex flex-column d-md-table-row">
                    <v-divider class="d-md-none"></v-divider>
                    <td :colspan="computedHeaders.length-1" class="d-flex justify-space-around d-md-table-cell table-td">
                        <v-alert type="error" dense block class="my-5 ma-md-0">
                            {{ $t('forms.GC_CommonForm.line_error') }}
                        </v-alert>
                    </td>
                    <td class="d-flex justify-space-around d-md-table-cell">
                        <v-btn color="error" @click="removeProduct(item, index)" icon :disabled="items.length == 1">
                            <v-icon small>fas fa-trash</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
            <template v-else-if="notFound[index]">
                <tr class="d-flex flex-column d-md-table-row">
                    <v-divider class="d-md-none"></v-divider>
                    <td :colspan="computedHeaders.length-1" class="d-flex justify-space-around d-md-table-cell table-td">
                        <v-alert type="warning" dense block class="my-5 ma-md-0">
                            {{ $t('forms.GC_CommonForm.line_not_found', { type_id: item.type_id }) }}
                        </v-alert>
                    </td>
                    <td class="d-flex justify-space-around d-md-table-cell">
                        <v-btn color="error" @click="removeProduct(item, index)" icon :disabled="items.length == 1">
                            <v-icon small>fas fa-trash</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
            <tr v-else class="d-flex flex-column d-md-table-row">
                <v-divider class="d-md-none"></v-divider>
                <td class="d-flex align-center py-md-2 d-md-table-cell table-td">
                    <TypeInput
                        v-model="item.type_id"
                        class="table-input"
                        :label="computedHeaders[0].text"
                        :hideLabel="$vuetify.breakpoint.mdAndUp"
                        :placeholder="$t('forms.GC_CommonForm.type.placeholder')"
                        @blur="onTypeBlur(item, index)"
                        :disabled="loading"
                        item-value="id"
                        :returnObject="false"
                        hide-details
                        solo dense
                        @typeLoading="value => $emit('typeLoading', value)"
                    />
                </td>
                <td class="d-flex align-center py-md-2 d-md-table-cell table-td">
                    <CurrencyInput
                        v-model="item.value"
                        class="table-input"
                        @blur="onValueBlur(item, index)"
                        :label="computedHeaders[1].text"
                        :hideLabel="$vuetify.breakpoint.mdAndUp"
                        :placeholder="$t('forms.GC_CommonForm.value.placeholder')"
                        :disabled="loading"
                        prefixed
                        hide-details
                        solo dense
                        :loading="isLoading[index]"
                    />
                </td>
                <td v-if="shouldShowAmount" class="d-flex align-center py-md-2 d-md-table-cell table-td">
                    <CurrencyInput
                        v-model="item.amount"
                        class="table-input"
                        @blur="onAmountBlur(item, index)"
                        :label="computedHeaders[2].text"
                        :hideLabel="$vuetify.breakpoint.mdAndUp"
                        :placeholder="$t('forms.GC_CommonForm.amount.placeholder')"
                        :suffixed="true"
                        :disabled="loading"
                        hide-details
                        solo dense
                        :loading="isLoading[index]"
                    />
                </td>
                <td v-if="$vuetify.breakpoint.mdAndUp || item.calculation_rule && !item.calculation_rule.in_ratio" class="d-flex justify-space-around d-md-table-cell">
                    <v-tooltip bottom v-if="item.calculation_rule && !item.calculation_rule.in_ratio">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs" color="warning">fas fa-exclamation-triangle</v-icon>
                        </template>
                        <span>{{ $t('forms.GC_CommonForm.zero_value_warning') }}</span>
                    </v-tooltip>
                </td>
                <td :colspan="$vuetify.breakpoint.smAndDown && item.calculation_rule && item.calculation_rule.in_ratio ? 2 : 1" class="d-flex justify-space-around d-md-table-cell">
                    <v-btn color="error" @click="removeProduct(item, index)" icon :disabled="items.length == 1">
                        <v-icon small>fas fa-trash</v-icon>
                    </v-btn>
                </td>
            </tr>
            <template v-if="index == items.length - 1">
                <v-divider class="d-md-none"></v-divider>
                <tr class="d-flex justify-space-between d-md-table-cell mt-2 m-md-0 add-btn">
                    <td :colspan="computedHeaders.length">
                        <v-btn @click="addProduct" text class="px-0 primary--text">
                            <span>{{ $t('forms.GC_CommonForm.add_product') }}</span>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </template>

    </v-data-table>
</template>
<script>
import CurrencyInput   from '@/components/inputs/CurrencyInput'
import TypeInput       from '@/components/inputs/TypeInput'
import CalculationRule from '@/models/CalculationRule'
import { roundNumber } from '@/utils/mathUtils'
import { gt } from '@/utils/validation'

export default {
    name: 'GCTypesTable',
    props: {
        items: {
            type: Array,
            required: true,
        },
        loading: Boolean,
        formType: String,
    },
    data: vm => ({
        isLoading        : [],
        hasError         : [],
        notFound         : [],
        hasZeroWarning   : [],
        gtz              : [gt(0)],
        validItemsCounter: 0,
    }),
    components: {
        CurrencyInput,
        TypeInput,
    },
    async created() {
        if (this.items.length) {
            for (let index in this.items) {
                let item = this.items[index]
                await this.onTypeBlur(item, index)
            }
            this.updateValidItemsCounter()
        }
    },
    methods: {
        removeProduct(item, index) {
            this.items.splice(index, 1)
            this.isLoading.splice(index, 1)
            this.hasError.splice(index, 1)
            this.notFound.splice(index, 1)
            this.updateValidItemsCounter()
        },
        async addProduct() {
            this.items.push({
                type_id: 'default',
                amount: 0,
                value: 0,
            })
            let lastIndex = this.items.length-1
            let lastItem = this.items[lastIndex]
            await this.onTypeBlur(lastItem, lastIndex)
            this.updateValidItemsCounter()
        },
        async onTypeBlur(item, index) {
            // Pega a regra de cálculo correspondente a este tipo
            this.$set(this.hasError, index, false)
            this.$set(this.isLoading, index, true)
            let response = await this.$axios.get(`/calculation-rule/${item.type_id}`)
                .catch(err => {
                    if (err.response.status != 404)
                        this.errorHandler(item, index)
                })
            this.$set(this.isLoading, index, false)

            // Caso o tipo não tenha regra de cálculo correspondente, pega a regra default
            if (!response || !response.data) {
                this.$set(this.isLoading, index, true)
                response = await this.$axios.get(`/calculation-rule/${this.$constants.getConstant('DEFAULT_CLR_ID')}`)
                    .catch(this.errorHandler(item, index))
                this.$set(this.isLoading, index, false)
            }

            if (!response)
                return

            var calculationRule = new CalculationRule(response.data)
            item.calculation_rule = calculationRule

            this.onValueBlur(item, index)
        },
        async onValueBlur(item, index) {
            // Pega a regra de cálculo correspondente a este tipo
            this.$set(this.hasError, index, false)
            this.$set(this.hasZeroWarning, index, false)
            if (!item.calculation_rule) {
                this.$set(this.isLoading, index, true)
                let response = await this.$axios.get(`/calculation-rule/${item.type_id}`)
                    .catch(this.errorHandler(item, index))
                this.$set(this.isLoading, index, false)

                if (!response)
                    return

                var calculationRule = new CalculationRule(response.data)
            } else {
                calculationRule = item.calculation_rule
            }

            this.$set(item, 'amount', Number(roundNumber(item.value * calculationRule.in_ratio)))
            this.$set(item, 'value', this.calculationValue(calculationRule.in_ratio, item.amount, index))
            this.$set(item, 'calculation_rule', calculationRule)
        },
        async onAmountBlur(item, index) {
            this.$set(this.hasError, index, false)
            this.$set(this.hasZeroWarning, index, false)
            if (!item.calculation_rule) {
                this.$set(this.isLoading, index, true)
                // Pega a regra de cálculo correspondente a este tipo
                let response = await this.$axios.get(`/calculation-rule/${item.type_id}`)
                    .catch(this.errorHandler(item, index))
                this.$set(this.isLoading, index, false)

                if (!response)
                    return

                var calculationRule = new CalculationRule(response.data)
            } else {
                calculationRule = item.calculation_rule
            }

            this.$set(item, 'value', this.calculationValue(calculationRule.in_ratio, item.amount, index))
            this.$set(item, 'amount', Number(roundNumber(item.value * calculationRule.in_ratio)))
            this.$set(item, 'calculation_rule', calculationRule)
        },
        calculationValue(in_ratio, amount, index) {
            if (in_ratio == 0) {
                this.$set(this.hasZeroWarning, index, true)
                return 0
            }

            return Number(roundNumber(amount / in_ratio))
        },
        errorHandler(item, index) {
            return e => {
                let status = this.$lodash.get(e, 'response.status')

                if (status == 404) {
                    this.$set(this.notFound, index, true)
                    this.$set(item, 'value', 0)
                    this.$set(item, 'amount', 0)
                    this.$set(item, 'calculation_rule', null)
                    this.updateValidItemsCounter()
                    return
                }

                this.$set(this.hasError, index, true)
                this.$console.error(e)
            }
        },

        updateValidItemsCounter() {
            let counter = this.items
                .filter(i => !!i.calculation_rule)
                .length
            this.validItemsCounter = counter
        },

        presubmit() {
            for (let index=0; index < this.items.length; index++) {
                let item = this.items[index]
                if (!item.calculation_rule) {
                    this.removeProduct(item, index--)
                }
            }

            return !!this.items.length
        },
    },
    computed: {
        productsValueTotal() {
            return this.items.reduce((carry, p) => Number(roundNumber(carry + p.value)) , 0)
        },
        productsAmountTotal() {
            return this.items.reduce((carry, p) => Number(roundNumber(carry + p.amount)) , 0)
        },

        computedHeaders() {
            let res = [
                { text: this.$t('forms.GC_CommonForm.type.label'),   sortable: false, },
                { text: this.$t('forms.GC_CommonForm.value.label'),  sortable: false, },
                { text: '',                                          sortable: false, width: '1%' },
                { text: this.$t('forms.actions'),                    sortable: false, width: '1%' },
            ]

            if (this.shouldShowAmount)
                res.splice(2, 0, { text: this.$t('forms.GC_CommonForm.amount.label'), sortable: false, align: 'center' })

            return res
        },

        shouldShowAmount() {
            return this.formType == 'generate'
        },
    }
}
</script>

<style lang="scss" scoped>
@media
only screen and (max-width: 960px) {
    .table-input {
        width: 100%;
    }

    .table-td {
        min-height: 80px;
    }

    .theme--light.v-data-table thead tr th {
        display: none !important;
    }
}

.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:last-child td:not(.v-data-table__mobile-row),
.theme--light.v-data-table thead tr:last-child th {
    border-bottom: solid 1px $table-divider-color;
}

.theme--light.v-data-table thead tr th, .v-data-table__mobile-row__header {
    font-size: 14px;
    color: $secondary-font-color;
    font-weight: normal;
}

.theme--light.v-data-table thead tr th:last-child span, tr td:last-child .v-data-table__mobile-row__header {
    display: none;
}

tr .v-data-table__mobile-row:last-child {
    justify-content: center;
}

.v-data-table .v-toolbar__content {
    padding-left: 15px;
    padding-right: 5px;
}

.theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.v-data-table table .add-btn:hover {
  background:  #FFFFFF00 !important;
}
</style>